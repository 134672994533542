<script>
	import PhoneInput from '$lib/Components/Forms/PhoneInput/PhoneInput.svelte';
	import { fly, slide } from 'svelte/transition';
	import { formFieldProxy } from 'sveltekit-superforms';

	let {
		element = $bindable(),
		form,
		name,
		label: labelString = null,
		required = null,
		type = 'text',
		placeholder = null,
		autocomplete = null,
		disabled = null,
		loading = $bindable(),
		checkboxInverted = false,
		defaultValue = null,
		tabindex = null,
		rows = null,
		list = null,
		class: className = '',
		phoneCountry = null,
		phoneNational = null,
		valueField = 'value',
		labelField = 'label',
		options = [],
		oninput,
		onchange,
		onfocus,
		labelSlot: label,
		...rest
	} = $props();

	let { form: innerForm } = form;
	const field = formFieldProxy(form, name);
	const { value, errors, constraints } = field;
	let boolValue = $state($value);
	let invertedValue = $state(!$value);
	$effect(() => {
		if (type == 'checkbox') {
			if (checkboxInverted) {
				$value = invertedValue ? false : true;
			} else {
				$value = boolValue ? true : false;
			}
		}
	});
</script>

{#if type == 'radio'}
	<fieldset
		class={[
			'relative col-span-full mb-4 grid content-between items-start justify-items-start gap-x-4',
			className ?? '',
		].join(' ')}
		class:cursor-not-allowed={disabled}
	>
		<legend class="mb-2">
			{labelString}
		</legend>
		<div class="grid w-full grow grid-flow-col">
			{#each options as option (option[valueField])}
				<label class="flex w-auto gap-x-2">
					<input
						{tabindex}
						{disabled}
						class:cursor-not-allowed={disabled}
						class="form-radio ring-indow-blue checked:bg-indow-blue"
						value={option[valueField]}
						checked={option[valueField] == defaultValue}
						{name}
						type="radio"
						bind:group={$innerForm[name]}
						{oninput}
						{onchange}
						{onfocus}
					/>
					<div>
						{option[labelField]}
					</div>
				</label>
			{/each}
		</div>
	</fieldset>
{:else}
	<label
		class:grid-flow-col={type == 'checkbox'}
		class:grid-cols-[auto_1fr]={type == 'checkbox'}
		class:mb-0={type == 'checkbox'}
		class:col-span-auto={type == 'checkbox'}
		class:cursor-not-allowed={disabled}
		class={[
			'relative col-span-full mb-4 grid content-between items-start justify-items-start gap-2',
			className,
		]}
	>
		<span class:self-center={type == 'checkbox'}>
			{@render label?.()}
			{labelString ?? ''}</span
		>
		<div class="relative grid w-full" class:col-start-1={type == 'checkbox'}>
			{#if type == 'email'}
				<input
					{name}
					{autocomplete}
					{tabindex}
					{disabled}
					class:cursor-not-allowed={disabled}
					type="email"
					placeholder=""
					aria-invalid={$errors ? 'true' : undefined}
					class="border-indow-grey-light w-full content-center rounded-md"
					class:border-red-900={$errors}
					bind:value={$value}
					bind:this={element}
					{oninput}
					{onchange}
					{onfocus}
					{...$constraints}
					{required}
				/>
			{:else if type == 'tel'}
				<PhoneInput
					{name}
					class="border-indow-grey-light w-full content-center rounded-md "
					bind:value={$value}
					{disabled}
					on:input
					on:focus
					on:change
					{...$constraints}
					{required}
					{phoneCountry}
					{phoneNational}
				></PhoneInput>
			{:else if type == 'checkbox'}
				{#if checkboxInverted}
					<!-- TODO: tailwind not working to set color for some reason? -->
					<input
						{name}
						{autocomplete}
						{tabindex}
						{disabled}
						class:cursor-not-allowed={disabled}
						type="checkbox"
						bind:checked={invertedValue}
						aria-invalid={$errors ? 'true' : undefined}
						style="color: #8cc7d2"
						class="form-checkbox border-indow-grey-light text-indow-blue h-8 w-8 content-center rounded-md"
						class:border-red-900={$errors}
						bind:this={element}
						{oninput}
						{onchange}
						{onfocus}
						{...$constraints}
						{required}
					/>
				{:else}
					<input
						{name}
						{autocomplete}
						{tabindex}
						{disabled}
						class:cursor-not-allowed={disabled}
						type="checkbox"
						bind:checked={boolValue}
						aria-invalid={$errors ? 'true' : undefined}
						style="color: #8cc7d2"
						class="form-checkbox border-indow-grey-light text-indow-blue h-8 w-8 content-center rounded-md"
						class:border-red-900={$errors}
						bind:this={element}
						{oninput}
						{onchange}
						{onfocus}
						{...$constraints}
						{...rest}
						{required}
					/>
				{/if}
			{:else if type == 'search'}
				{#if loading}
					<div
						transition:fly|local
						class="absolute right-0 grid h-full place-items-center"
					>
						<svg
							class="stroke-indow-grey m-2 w-6"
							stroke="#000"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							><g class="spinner"
								><circle
									cx="12"
									cy="12"
									r="9.5"
									fill="none"
									stroke-width="3"
								/></g
							></svg
						>
					</div>
				{:else}
					<div
						transition:fly|local
						class="absolute right-0 grid h-full place-items-center"
					>
						<svg
							class="bg-indow-white fill-indow-grey m-2 w-6"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
				{/if}
				<input
					{name}
					{autocomplete}
					{tabindex}
					{disabled}
					class:bg-indow-grey-light={disabled}
					class:cursor-not-allowed={disabled}
					type="search"
					aria-invalid={$errors ? 'true' : undefined}
					class="border-indow-grey-light w-full content-center rounded-md"
					class:border-red-900={$errors}
					bind:value={$value}
					bind:this={element}
					{oninput}
					{onchange}
					{onfocus}
					{...$constraints}
					{required}
				/>
			{:else if type == 'textarea'}
				<textarea
					{name}
					{rows}
					{autocomplete}
					{placeholder}
					{tabindex}
					{disabled}
					class:cursor-not-allowed={disabled}
					aria-invalid={$errors ? 'true' : undefined}
					class="border-indow-grey-light bg-indow-white w-full rounded-md"
					class:border-red-900={$errors}
					bind:value={$value}
					bind:this={element}
					{oninput}
					{onchange}
					{onfocus}
					{...$constraints}
					{required}
					class:bg-indow-grey-light={disabled}
				></textarea>
			{:else if type == 'select'}
				<select
					bind:value={$value}
					bind:this={element}
					{oninput}
					{onchange}
					{onfocus}
					class="border-indow-grey-light bg-indow-white w-full content-center rounded-md leading-normal"
					class:border-red-900={$errors}
					{name}
					{placeholder}
					{required}
					{disabled}
					class:cursor-not-allowed={disabled}
					class:!bg-indow-grey-light={disabled}
					{autocomplete}
					{tabindex}
				>
					{#each options as option (option[valueField])}
						<option
							value={option[valueField]}
							selected={option[valueField] == $value}
						>
							{option[labelField]}
						</option>
					{/each}
				</select>
			{:else}
				<input
					{name}
					{autocomplete}
					{tabindex}
					type="text"
					placeholder=""
					aria-invalid={$errors ? 'true' : undefined}
					class="border-indow-grey-light w-full content-center rounded-md"
					class:border-red-900={$errors}
					bind:value={$value}
					bind:this={element}
					{list}
					{oninput}
					{onchange}
					{onfocus}
					{...$constraints}
					{required}
					{disabled}
					class:bg-indow-grey-light={disabled}
					class:cursor-not-allowed={disabled}
				/>
			{/if}
		</div>
	</label>
{/if}
{#if $errors}
	<span transition:slide class="col-span-full mb-4 text-left text-red-900"
		>{$errors}</span
	>
{/if}

<style>
	.spinner {
		transform-origin: center;
		animation: spinner_rotate 2s linear infinite;
	}
	.spinner circle {
		stroke-linecap: round;
		animation: spinner_offset 1.5s ease-in-out infinite;
	}
	@keyframes spinner_rotate {
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes spinner_offset {
		0% {
			stroke-dasharray: 0 150;
			stroke-dashoffset: 0;
		}
		47.5% {
			stroke-dasharray: 42 150;
			stroke-dashoffset: -16;
		}
		95%,
		100% {
			stroke-dasharray: 42 150;
			stroke-dashoffset: -59;
		}
	}
	input[type='search']::-webkit-search-cancel-button {
		appearance: none;
	}
</style>
